import React from 'react';

import TextContent from 'components/shared/text-content';
import MainLayout from 'layouts/main';

const Imprint = () => (
  <MainLayout language="de" menuItems={[{ label: { de: 'Home' }, path: '/de/' }]}>
    <TextContent>
      <h2 id="angabengem5tmg">Angaben gemäß §5 TMG</h2>
      <p>
        LIMEBIRD GmbH
        <br />
        Theodor-Mommsen-Str. 1
        <br />
        68766 Hockenheim
      </p>

      <p>
        Handelsregister: HRB 736639
        <br />
        Registergericht: Amtsgericht Mannheim
      </p>

      <p>
        <strong>Vertreten durch:</strong>
        <br />
        Bernd Brunner, Christian Wahler
      </p>

      <h2 id="kontakt">Kontakt</h2>
      <p>
        Telefon: +49 (0) 173 792 8330
        <br />
        Telefax: +49 (0) 32 223357964
        <br />
        E-Mail: <a href="mailto:info@limebird.io">info@limebird.io</a>
      </p>
      <h2 id="eustreitschlichtung">EU-Streitschlichtung</h2>
      <p>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
        <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>. Unsere
        E-Mail-Adresse finden Sie oben im Impressum.
      </p>
      <h2 id="verbraucherstreitbeilegunguniversalschlichtungsstelle">
        Verbraucherstreitbeilegung/Universalschlichtungsstelle
      </h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <h2 id="haftungfrinhalte">Haftung für Inhalte</h2>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
        denallgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
        jedoch nichtverpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
        oder nach Umständen zuforschen, die auf eine rechtswidrige Tätigkeit hinweisen.
      </p>
      <p>
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
        allgemeinenGesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
        ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
        von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </p>
      <h2 id="haftungfrlinks">Haftung für Links</h2>
      <p>
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen
        Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
        Für die Inhalte derverlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
        Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
        mögliche Rechtsverstöße überprüft. Rechtswidrige Inhaltewaren zum Zeitpunkt der Verlinkung
        nicht erkennbar.
      </p>
      <p>
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>
      <h2 id="urheberrecht">Urheberrecht</h2>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
        deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
        des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
        privaten, nicht kommerziellen Gebrauch gestattet.
      </p>
      <p>
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
        Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
        gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
        bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </p>
      <p>
        Quelle:{' '}
        <a href="https://www.e-recht24.de/impressum-generator.html">
          https://www.e-recht24.de/impressum-generator.html
        </a>
      </p>
    </TextContent>
  </MainLayout>
);

export default Imprint;
